 <template>
	<el-dialog title="修改SKU(梦天恒凯)" top="1vh" width="600px" :visible.sync="is_show_in_page">
		<el-form label-width='80px'>
			<el-form-item label="品名规格">
				<el-input v-model="form.sku_name" clearable></el-input>
			</el-form-item>
			<el-form-item label="转换后">
				<el-input v-model="form.transit_name" clearable></el-input>
			</el-form-item>
			<el-form-item label="备注">
				<el-input v-model="form.mark" clearable></el-input>
			</el-form-item>
			<el-form-item >
				<el-button type="primary" @click="sub">提交</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
	export default {
		props:{
			is_show:Number,
			data:Object,
		},
		data() {
			return {
				
				//是否显示
				is_show_in_page:false,

				//表单
				form:{
					sku_name:'',
					transit_name:'',
					mark:'',
				},
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){

					//打开弹出层
					this.is_show_in_page=true;
					
					//初始化
					this.init();

				}else{

					//关闭弹出层
					this.is_show_in_page=false;
				}
			}
		},
		methods: {

			//初始化
			init(){
				this.form.sku_name=this.data.sku_name
				this.form.transit_name=this.data.transit_name
				this.form.mark=this.data.mark
			},

			//提交
			sub(){

				if(!this.form.sku_name.trim()){
					this.$my.other.msg({
						type:'warning',
						str:"缺少品名规格"
					});
					return;
				}

				if(!this.form.transit_name.trim()){
					this.$my.other.msg({
						type:'warning',
						str:"缺少转换后名称"
					});
					return;
				}

				//调用接口
				this.$my.net.req({
					data:{
						mod:'sku',
						ctr:'sku_of_mengtian_qingyuan_edit_by_admin',
						id:this.data.id,
						...this.form
					},
					callback:(data)=>{

						//报告结果
						this.$my.other.msg({
							type:'success',
							str:"修改成功"
						});

						//关闭弹出层
						this.is_show_in_page=false;

						//通知
						this.$emit("edited");
					}
				});
			}
		}
	}
</script>